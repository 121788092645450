import { getContentfulLocale, getUrlLocale } from '@dfds-pax/common'
import { getClientEnvironment, logger } from '@dfds-pax/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'

const getTitle = (slugId: string, contentResult: any) => {
  if (slugId === '4SQzhVFYxYPQkcy3urgjFi')
    return contentResult.items.find((item: any) => item.fields.entryTitle === 'MENU-ONE-TITLE').fields?.value
  if (slugId === '5KCuKuA1SD3nEZyStlxP9u')
    return contentResult.items.find((item: any) => item.fields.entryTitle === 'MENU-TWO-TITLE').fields?.value
  if (slugId === '5P3CeDTyiA7ugyp8Uy1QvM')
    return contentResult.items.find((item: any) => item.fields.entryTitle === 'MENU-THREE-TITLE').fields?.value
  return 'NOT FOUND'
}

export default async function Content() {
  const urlLocale = getUrlLocale()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const contentTaskUrl = new URL('https://cdn.contentful.com/spaces/z860498abl84/environments/master/entries')
  contentTaskUrl.searchParams.append('content_type', 'paxObResource')
  contentTaskUrl.searchParams.append('locale', contentfulLocale)
  contentTaskUrl.searchParams.append('fields.applications', 'login')
  contentTaskUrl.searchParams.append('limit', '100')

  const contentTask = httpClient(contentTaskUrl.toString(), {
    Authorization: 'Bearer cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665',
  })

  const urlSlugList = '4SQzhVFYxYPQkcy3urgjFi,5KCuKuA1SD3nEZyStlxP9u,5P3CeDTyiA7ugyp8Uy1QvM'

  const urlSlugTaskUrl = new URL('https://cdn.contentful.com/spaces/mivicpf5zews/environments/master/entries')
  urlSlugTaskUrl.searchParams.append('content_type', 'urlSlug')
  urlSlugTaskUrl.searchParams.append('locale', contentfulLocale)
  urlSlugTaskUrl.searchParams.append('sys.id[in]', urlSlugList)
  urlSlugTaskUrl.searchParams.append('limit', '100')

  const urlSlugsTask = httpClient(urlSlugTaskUrl.toString(), {
    Authorization: 'Bearer ffb6d2e75bdeb11580a166b1856d1b183e180c6da95a97998ab50cd12ad9eb85',
  })

  const [contentResult, urlSlugsResult] = await Promise.all([contentTask, urlSlugsTask])

  const result = {
    menuContent: {
      LOGOUT: contentResult.items.find((item: any) => item.fields.entryTitle === 'LOGOUT')?.fields?.value,
      LOGIN: contentResult.items.find((item: any) => item.fields.entryTitle === 'LOGIN')?.fields?.value,
      MY_PROFILE: contentResult.items.find((item: any) => item.fields.entryTitle === 'MY-PROFILE')?.fields?.value,
      CREATE_NEW_ACCOUNT: contentResult.items.find((item: any) => item.fields.entryTitle === 'CREATE-NEW-ACCOUNT')?.fields?.value,
      FIRST_TIME_HERE: contentResult.items.find((item: any) => item.fields.entryTitle === 'FIRST-TIME-HERE')?.fields?.value,
    },
    menuItems: urlSlugsResult.items.map((item: any) => {
      return { id: item.sys.id, slug: item.fields.slug, title: getTitle(item.sys.id, contentResult) }
    }),
  }

  return result
}

async function httpClient(url: string, headers?: HeadersInit) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  }

  try {
    const response = await fetch(url, requestOptions)
    if (!response.ok) {
      let responseObj
      let jsonError
      try {
        responseObj = await response.json()
      } catch (error) {
        jsonError = 'Could not parse response body as JSON'
      }
      let errorMessage =
        responseObj?.message || responseObj?.Message || responseObj?.title || jsonError || 'Message did not exist in error response.'
      errorMessage += ' status: ' + response.status + ' url: ' + url
      const httpClientError = new Error(errorMessage)
      throw httpClientError
    }
    return await response.json()
  } catch (error: any) {
    var payload: LogProps = {
      message: error?.message || 'Unknown fetch error',
      messageTemplate: 'Error geting content from contentful',
      level: 'Error',
      fields: {
        correlationId: uuidv4(),
        application: 'azure-login',
        environment: getClientEnvironment(),
        frontend: true,
        path: window.location.pathname,
        referer: document.referrer,
        userAgent: navigator.userAgent,
        error: {
          error: error?.name,
          stackTrace: error?.componentStack,
        },
      },
    }
    logger('Error', getClientEnvironment(), payload)
  }
}
